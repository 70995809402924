import React from "react";
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"
import LayoutTwo from "../components/layoutTwo";
import { PageLinks } from "../common/site/page-static-links";
import Seo from "../components/seo"
import { PageContents } from "../common/site/page-content";
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const Banner = loadable(() => import("../components/Banner/AreaGuideBanner"));
const AreaGuideDetailDesc = loadable(() => import("../components/AreaGuideDetailDesc/AreaGuideDetailDesc"));
const PropertyDetailsMap = loadable(() => import("../components/PropertyDetailsMap/PropertyDetailsMap"));
const AreaGuideProperties = loadable(() => import("../components/FeaturedProperties/AreaGuideProperties"));
const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModuleGlobal"));

const AreaGuideDetails = ({ data }, props) => {

    const { t } = useTranslation()
    const { locale } = useLocalization() 

    const PageData = data?.strapiAreaGuide

    let breadcrumData;

    if (PageLinks?.areaguide_parent_label) {
        breadcrumData = { parentname: t("areaguide_parent_label"), parent: PageLinks.areaguide_parent, subparentname: t("areaguide_label"), subparent: PageLinks.areaguide, pagename: PageData.title }
    } else {
        breadcrumData = { parentname: t("areaguide_label"), parent: PageLinks.areaguide, pagename: PageData.title }
    }
    let popsearch = "Generic pages"
    if(locale == "ar")
        popsearch = "الصفحات العامة"

    return (
        <LayoutTwo popularSearch={popsearch}>
            <div className="layout-padding-top">
                <BreadcrumbModule  {...breadcrumData} />
                <Banner tag="landing" title={PageData.title} banner_title={`${PageData.title} ${t("areaguide_title")}`} image={PageData.banner_image} banner_content={PageData.banner_content} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms?.banner_section_banner_image_Transforms} page="areaguide" />


                <AreaGuideDetailDesc {...PageData} />

                {PageData.latitude && PageData.longitude && <PropertyDetailsMap
                    lat={PageData.latitude}
                    lng={PageData.longitude}
                />}

                <AreaGuideProperties tag="areaguide-properties" areaname={PageData.title}/>

                <ValuationModule />
            </div>
        </LayoutTwo>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiAreaGuide  
    return (
        <Seo title={PageData.title} description={`Know more about ${PageData.title} here. Contact one of our estate agents for assistance in finding the right property for you in ${PageData.title}.`} />
    )
}

export default AreaGuideDetails

export const query = graphql`
query ($locale: String!, $page_id: Int) {
    strapiAreaGuide(strapi_id: {eq: $page_id}, locale: { eq: $locale }) {
        ...AreaGuideFragment
        banner_content {
            data {
            banner_content
            }
        }
        banner_image {
          alternativeText
          url
        }
        latitude
        longitude
        add_content {
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                ...PlainContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE {
                ...ImageFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
                ...AccordionFragment
            }
        }
    }
    
  }
`